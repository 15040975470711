/** @jsx jsx */
import React, { useEffect, useReducer } from "react"

import Amplify from "@aws-amplify/core";
import Analytics from '@aws-amplify/analytics';
import { API, graphqlOperation, Cache/*Hub, Logger*/ } from "aws-amplify"

import { getAssetDeltaObject, getLegacyShotwireAsset, assetDeltaUpdate } from "../graphql/videoPlayerQueries"
import { getShotwireAssetsDeltaPublic  } from "../graphql/shotassetQueries"
import { getShotwireAssetsDelta } from "../graphql/queries"

import Helmet from 'react-helmet'

// import {loadStripe} from '@stripe/stripe-js';

import { Box, jsx } from 'theme-ui'
import { Link, navigate } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Image from 'react-bootstrap/Image'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import styled from '@emotion/styled'
// import InfoCard from '../@geocine/gatsby-theme-organization/components/InfoCard'
// import Layout from '../@geocine/gatsby-theme-organization/components/Layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faDownload, faCartPlus, faCartArrowDown, faHome, faEnvelope, faPhone, faPlay, faPause, faSpinner, faVideoSlash, faHeart, faStar, faPiggyBank, faPlus, faMinus, faCheck, faCoins, faLink } from "@fortawesome/free-solid-svg-icons";

import ReactPlayer from 'react-player'
// import ReactJWPlayer from 'react-jw-player';
import ReactJWPlayer from '../@geocine/gatsby-theme-organization/components/JwVideoPlayer'

// import Layout from "../components/layout"
// import SEO from "../components/seo"
// import CoinbaseCommerceButton from 'react-coinbase-commerce';
// import 'react-coinbase-commerce/dist/coinbase-commerce-button.css';

import 'src/css/theme.scss'
import favicon from '../@geocine/gatsby-theme-organization/assets/favicon.ico'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'

import awsconfig from "../aws-exports";
import { AppsContext } from '../AppsProvider'

// import { Authenticator, SignIn, SignUp, ConfirmSignUp, Greetings } from 'aws-amplify-react';


Amplify.configure(awsconfig);
// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false

// const analyticsConfig = {
//   AWSPinpoint: {
//         // Amazon Pinpoint App Client ID
//         appId: '12039a8ccae04f7fab5cbff6eb095368',
//         // Amazon service region
//         region: 'us-east-1',
//         mandatorySignIn: false,
//   }
// }

// Analytics.configure(analyticsConfig)

var stripePromise, checkoutSessionId

const WatermarkLogoDiv = styled('div')`
  position: absolute;
  top: 20px;
  left: 35px;
  opacity: 0.6;
  display:none;
`
 
const UserOpsDiv = styled('div')`
  
  top: 10px;
  left: 0px;
  
`

// const MySectionHeader = Object.assign({}, AmplifyTheme.sectionHeader, { background: 'orange' });
// const MyTheme = Object.assign({}, AmplifyTheme, { sectionHeader: MySectionHeader });
const MyTheme = {
    signInButtonIcon: { 'display': 'none' },
    googleSignInButton: { 'backgroundColor': 'red', 'borderColor': 'red' },
    button: { 'backgroundColor': 'red', 'borderColor': 'red' },
    navButton: { 'backgroundColor': 'red', 'borderColor': 'red' }


}

const MetaEditable = ['caption', 'headline', 'credit', 'byline', 'keywords', 'suppCat', 'city', 'state', 'country']

const categoryMap = {
  CelebrityCandids:'Celebrity Candids',
  RedCarpetEvents:'Red Carpet',
  News:'News',
  Sports: 'Sports',
  Music: 'Music',
  Fashion: 'Fashion',
  Travel: 'Travel',
  Arts: 'Arts',
  Nature: 'Nature',
  Aerial: 'Aerial',
  CelebrityInterviews:'Celebrity Interviews'
}

const formArchivedMap = {
  "false":'Not Published / Archived',
  "true":'Published / Archived ',
}

const formMarketplaceMap = {
  "false":'Private',
  "true":'Licensing Available',
}

var setupElements = async function() {
  // stripePromise = await loadStripe("pk_live_xVFKV4FGy3ycA15Ft5vPBZDl");
};


const VideoPlayerB = ({location}) => {
  // console.log('location:', location)
  let userInfo = null, 
      uRoles = null, 
      uGroups = null, 
      metaForm = {}, 
      searchParams, 
      fileSize, 
      srcMediainfo, 
      mediainfo = null, 
      updateMediainfo = false, 
      cart = [],
      myDownloads = [],
      favs = [],
      modifiedThumbPlyr = false
  // console.log('location.state:',location.state)
    // console.log('location.search:',location.search)

  // let playerDiv = React.createRef()
   
  // const [state, dispatch] = useReducer(reducer, initialState);
  const appsContext = React.useContext(AppsContext)
  
  let vPlayer = appsContext.state.videoPlayer 
  // console.log('vPlayer:', vPlayer)

  // myDownloads = Cache.getItem('myDownloads')
  // cart = Cache.getItem('shopCart')
  const expiration = new Date(2020, 12, 18)

  const todayDate = new Date()
  const nowYr = todayDate.getFullYear()
  const nowMo = todayDate.getMonth()+1
  const nowDay = todayDate.getDate()
  



  const handlePlayerRef = () => {
    // e.preventDefault()
    console.log('handlePlayerRef')
    // console.log('video node:',playerDiv.current.querySelector('video'))
        // console.log('video node:',playerDiv.current)

    // setState({ playing: true })
    // vPlayer.playing = true
    // appsContext.dispatch({ type: "VPLAYER",  vPlayer });
  }

 

  async function assetDeltaObject(id){
    // console.log('get asset id:', id)
    // console.log('user:', appsContext.state.user)
    // console.log('appsContext.state.isAdmin:', appsContext.state.isAdmin)
    // console.log('appsContext.state.isUploader:', appsContext.state.isUploader)
    try{

        const assetObj = await API.graphql(
            {
                // query: getShotwireAssetsDelta,
                query: getShotwireAssetsDeltaPublic,
                variables: { id: id },
                authMode:  (appsContext.state.isAdmin===true || appsContext.state.isUploader===true || appsContext.state.user!==null)  ? 'AMAZON_COGNITO_USER_POOLS': 'AWS_IAM'
              }
            );
      

        // console.log('assetObj::', assetObj.data.getShotwireAssetsDelta)
      //               console.log('vPlayer:',vPlayer)
        const tmp = vPlayer
        //update vplayer
        // vPlayer.signedUrl=''
        // tmp.image = assetObj.data.getShotwireAssetObject.thumbNailUrl
        tmp.assetObj = assetObj.data.getShotwireAssetsDelta
        // vPlayer.playing=false
        // vPlayer.isDownloading=0
        // vPlayer.hrDlSrc= ''
        appsContext.dispatch({ type: "VPLAYER", vPlayer: tmp });

    }catch(err){
      // console.log('assetDeltaObject err', err)
    }
    
  }

  async function shotwireAssetObject(params){

    try{
    const assetObj = await API.graphql(graphqlOperation(getLegacyShotwireAsset, {realSetId: params.arsid, name:params.name } ))
    // console.log('assetObj::', assetObj)
    // console.log('assetObj=>>', assetObj.data.getLegacyShotwireAsset.items)
    //               console.log('vPlayer:',vPlayer)
                  const tmp = vPlayer
                  //update vplayer
                  // vPlayer.signedUrl=''
                  // tmp.image = assetObj.data.getShotwireAssetObject.thumbNailUrl
                  tmp.assetObj = assetObj.data.getLegacyShotwireAsset.items[0]
                  // vPlayer.playing=false
                  // vPlayer.isDownloading=0
                  // vPlayer.hrDlSrc= ''
                  appsContext.dispatch({ type: "VPLAYER", vPlayer: tmp });
                }catch(err){
                  // console.log('err:',err)
                }
  }


 


  async function updateAssetDelta(assetObj) {
    try{
      // console.log('date:', assetObj.setDate)
      if(typeof assetObj.setDate !== 'undefined'){
            const formattedDate = [assetObj.setDate.slice(0, 4), assetObj.setDate.slice(4,6), assetObj.setDate.slice(6,8)].join('-')
            // console.log('formattedDate:', formattedDate)
            const eventDate = new Date(formattedDate).getTime()
            // console.log('eventDate:',eventDate)
          }
      // const asset = {...assetObj, archived: 1, category: '', timestamp: eventDate } 
      const asset = {...assetObj }      
     
      Object.keys(asset).forEach((key) => (asset[key] == null||asset[key] == '') && delete asset[key]);
      // console.log('asset->', asset)
      if(asset.category=='SELECT A CATEGORY' && asset.archived==1){
        // console.log('abort, category required to archive.')
        appsContext.dispatch({ type:"TOASTS", toasts:{ show:true, display:'block', displayTopPosition: '1500px', title:"Error", body: 'A CATEGORY Must be selected to Publish a video.' }})
      }else{

        if(asset.archived==0 || asset.archived=="0" || asset.archived==="false" || asset.archived===false){
          asset.category = "Ingest"
        }
        // console.log('asset=>', asset)
        const savemeta = await API.graphql(graphqlOperation(assetDeltaUpdate, { input: asset }));
        // console.log('savemeta:', savemeta)
        appsContext.dispatch({ type: "METAUPDATE", isMetaSaving: false })
        appsContext.dispatch({ type: "SRCMEDIAINFOUPDATE", isSrcMediaInfoUpdating: false })

      }
      
    } catch(err) {
      appsContext.dispatch({ type: "METAUPDATE", isMetaSaving: false })
      appsContext.dispatch({ type: "SRCMEDIAINFOUPDATE", isSrcMediaInfoUpdating: false })
      // console.log('delta err:',err)
    }    
  }


useEffect(() => {
      // console.log('get for user product and stats...')
      let isMounted = true
      if(isMounted === true){
        // console.log('setting up user...')

          if(appsContext.state.user){
            const  userInfo  = appsContext.state.user
            // console.log('userInfo: ', userInfo)

            if(typeof userInfo.signInUserSession.idToken.payload[`cognito:groups`] != 'undefined'){
              uGroups = userInfo.signInUserSession.idToken.payload[`cognito:groups`]
              // console.log('uGroups:',uGroups)
              // console.log('uRoles: ', uRoles)
              if( uGroups.some(r => ['Admins'].indexOf(r) >= 0) ){
                // console.log('Admin user.')
                 appsContext.dispatch({ type: "ISADMIN", isAdmin:true }) 
               }
              if( uGroups.some(r => ['Uploaders'].indexOf(r) >= 0) ){
                // console.log('Uploader user.')
                 appsContext.dispatch({ type: "ISUPLOADER", isUploader:true }) 
               }
              // console.log('isAdmin:', isAdmin)
            }

         
          }
        }

        return () => {
                  //console.log('state2: ', state)
                  // subscription.unsubscribe();
                  isMounted = false
                  
                };

  },[ ])

  useEffect(() => {
          let isMounted = true
            
          if(isMounted === true){
            // console.log('mounted...')
             // setupElements();
             // cart = Cache.getItem('shotwire-shopCart')
             // appsContext.dispatch({type:"SHOPCART", shopCart:cart}) 
             // favs = Cache.getItem('shotwire-favorites')
             // appsContext.dispatch({type:"FAVORITES", favorites:favs})
             // myDownloads = Cache.getItem('myDownloads')
           
               
              // if(appsContext.state.user){
              //   userInfo  = appsContext.state.user
              //     // console.log('userInfo: ', userInfo)

              //     if(typeof userInfo.signInUserSession.idToken.payload[`cognito:groups`] != 'undefined'){
              //       uGroups = userInfo.signInUserSession.idToken.payload[`cognito:groups`]
              //       // console.log('uGroups:',uGroups)
              //       // console.log('uRoles: ', uRoles)
              //       if( uGroups.some(r => ['Admins'].indexOf(r) >= 0) ){
              //         // console.log('Admin user.')
              //          appsContext.dispatch({ type: "ISADMIN", isAdmin:true }) 
              //        }
              //       if( uGroups.some(r => ['Uploaders'].indexOf(r) >= 0) ){
              //         // console.log('Uploader user.')
              //          appsContext.dispatch({ type: "ISUPLOADER", isUploader:true }) 
              //        }
              //       // console.log('isAdmin:', isAdmin)
              //     }                      
              // }
              // console.log('userInfo:', userInfo)
              // console.log('video asset:', appsContext.state.videoPlayer)

              if(typeof appsContext.state.videoPlayer.assetObj.id == 'undefined'){
                try{
                  // console.log('location.search:', location.search)
                  // console.log('location.search.arsid:', location.search.arsid)
                  searchParams = new URLSearchParams(location.search)
                  // console.log('searchParams:', searchParams)
                  // console.log('location.search.aid:', searchParams.get("aid") )
                  // console.log('location.search.arsid:', searchParams.get("arsid") )
                  if(searchParams.get("aid")){
                    // console.log('aid:', searchParams.get("aid") )
                    
                    assetDeltaObject(searchParams.get("aid"))
                    

                  }
                  if(searchParams.get("arsid")){
                    // console.log('arsid:', searchParams.get("arsid") )
                    // console.log('location.search.an:', searchParams.get("an") )
                    shotwireAssetObject({arsid: (searchParams.get("arsid")), name:(searchParams.get("an")) })
                  }
                }catch(err){ 
                  // console.log('asset err:', err) 
                }
              
              }
            }
               // console.log('video player:', appsContext.state.videoPlayer)
              // const metaForm = {}
              // console.log('appsContext.state.videoPlayer.assetObj.setDate:',appsContext.state.videoPlayer.assetObj.setDate)
              // console.log('appsContext.state.metaForm.counter:', appsContext.state.metaForm.counter)
              if(typeof appsContext.state.metaForm.counter == 'undefined' || appsContext.state.metaForm.counter===0){
                // console.log('meta form init.')

                //populate metaform if empty
                if(appsContext.state.videoPlayer.assetObj.setDate!=null){

                  metaForm.yr = appsContext.state.videoPlayer.assetObj.setDate.slice(0, 4)
                  metaForm.mo = appsContext.state.videoPlayer.assetObj.setDate.slice(4,6) 
                  metaForm.day = appsContext.state.videoPlayer.assetObj.setDate.slice(6,8)
                  // console.log('yyyy, mo, dd => ', yr ,  mo , day)
                  
                }else{
                  metaForm.yr = nowYr
                  metaForm.mo = nowMo
                  metaForm.day = nowDay
                  
                }

                metaForm.category = appsContext.state.videoPlayer.assetObj.category
                metaForm.archived = appsContext.state.videoPlayer.assetObj.archived

                Object.keys(appsContext.state.videoPlayer.assetObj).map((keyName, i) => {
                  if(MetaEditable.includes(keyName)){
                    metaForm[keyName] = appsContext.state.videoPlayer.assetObj[keyName]
                  }
                })

                metaForm.counter = (appsContext.state.metaForm.counter) ? appsContext.state.metaForm.counter++ : 0
                metaForm.marketplace = (appsContext.state.videoPlayer.assetObj.marketplace) ? appsContext.state.videoPlayer.assetObj.marketplace : false

                // console.log('appsContext.state.videoPlayer.assetObj:',appsContext.state.videoPlayer.assetObj)
                
                if(typeof appsContext.state.videoPlayer.assetObj !== 'undefined'){
                
                  if(typeof appsContext.state.videoPlayer.assetObj.srcMediainfo !== 'undefined') srcMediainfo = JSON.parse(appsContext.state.videoPlayer.assetObj.srcMediainfo)
                  else{
                    //get media info by default if exists and update.

                   srcMediainfo = null
                  }
                }
                
                // console.log('srcMediainfo:', srcMediainfo)
                fileSize = appsContext.state.videoPlayer.assetObj.fileSize
                // console.log('fileSize.',fileSize)
                if( !(fileSize>0) ){
                   if(srcMediainfo !== null) fileSize = Math.round(srcMediainfo.container.fileSize/1048576)
                }
                // console.log('srcMediainfo->', srcMediainfo)
                // console.log('fileSize:',fileSize)
                appsContext.dispatch({ type:'VPLAYER',  vPlayer:{...appsContext.state.videoPlayer,fileSize:fileSize} } )

                appsContext.dispatch({type:'METAFORM', metaForm})

                Analytics.record({
                  name: 'VIDEO-B-PLAYER', 
                  // Attribute values must be strings
                  attributes: { 
                    id: appsContext.state.videoPlayer.assetObj.id,
                    video: appsContext.state.videoPlayer.assetObj.name,  
                    // highRezFileName: appsContext.state.videoPlayer.assetObj.highRezFileName, 
                    // setId: appsContext.state.videoPlayer.assetObj.realSetId,
                    // setDate: appsContext.state.videoPlayer.assetObj.setDate,
                    // signedUrl: appsContext.state.videoPlayer.assetObj,
                    // thumbNailUrl: appsContext.state.videoPlayer.assetObj.thumbNailUrl
                  }
                });

              

              }else{
                // console.log('updating meta form.')
                appsContext.dispatch({type:'METAFORM', metaForm})
              }
              
              

              

              // if(typeof appsContext.state !== 'undefined'){
                // console.log('appsContext.state.videoPlayer.assetObj.dashUrl:', appsContext.state.videoPlayer.assetObj.dashUrl)
                // console.log('appsContext.state.videoPlayer.assetObj.hlsUrl:',appsContext.state.videoPlayer.assetObj.hlsUrl)
                // console.log('appsContext.state.videoPlayer.assetObj.mp4Urls:',appsContext.state.videoPlayer.assetObj.mp4Urls)
                
                if(appsContext.state.videoPlayer.assetObj.dashUrl == null
                    && appsContext.state.videoPlayer.assetObj.hlsUrl == null
                    && appsContext.state.videoPlayer.assetObj.mp4Urls == null){
                  // console.log('getVideoUrl...')
                  getVideoUrl()
                } 
                // console.log('calling getVideoUrl(hrdl)')
                getVideoUrl('hrdl')
              // } 
             // console.log("assetObj: ",appsContext.state.videoPlayer.assetObj) 
             // console.log("videoPlayer: ",appsContext.state.videoPlayer.assetObj) 


              if(appsContext.state.isAdmin){
                // subscribe to asset meta updates to show changes in the video details? or just update from the meta form?
                // or just dispatch a call to get the asset data?
              }


                return () => {
                  // console.log('video player unmounting...')
                  isMounted = false
                  appsContext.dispatch({type:'METAFORM', metaForm:{counter:0}})
                  // vPlayer.signedUrl=''
                  // vPlayer.image=''
                  // vPlayer.assetObj={}
                  // vPlayer.playing=false
                  // vPlayer.isDownloading=0
                  // vPlayer.hlDlSrc= ''
                  // appsContext.dispatch({ type: "VPLAYER", vPlayer });
               
                };
          }, [ appsContext.state.videoPlayer.assetObj, appsContext.state.user, appsContext.state.videoPlayer.fileSize, appsContext.state.isAdmin, appsContext.state.isUploader ]);

  async function getVideoUrl(dlType) {
    


      try{
        // console.log('appsContext.state.videoPlayer.assetObj:', appsContext.state.videoPlayer.assetObj)

        // const eventDate = new Date(formattedDate).getTime()
        
        // console.log('hrDlSrc: ',appsContext.state.videoPlayer.hrDlSrc)
        const signedUrl = ''
        let tmp = {}
        /* This is for legacy pcn videos only
        const signedUrl = await API.get('pcnvideoUrls', '/videos', {
                        'queryStringParameters': {
                          'setId': appsContext.state.videoPlayer.assetObj.realSetId,
                          'setDate': appsContext.state.videoPlayer.assetObj.setDate,
                          'setName': appsContext.state.videoPlayer.assetObj.name,
                          'assetName': (dlType === 'hrdl') ? appsContext.state.videoPlayer.assetObj.highRezFileName : appsContext.state.videoPlayer.assetObj.highRezFileName.replace('.mp4','-h264.mp4'),

                        }
                      });
                      //*/
      // const albumData = await API.graphql(graphqlOperation(ListAllAssets,{coId:"6B3E1A00BDAB94E4DA166A30A759D44C"}));
        // console.log('signedUrl:', signedUrl)

        // dispatch({ type: "SRC", signedUrl: signedUrl.videoUrl });
        // videoPlayer.signedUrl = signedUrl.videoUrl 
        //   image: location.state.image,
        //   assetObj: location.state.assetObj
        // }
        if(dlType === 'hrdl'){
              // console.log('GET HRDL...')
              // console.log('appsContext.state.videoPlayer.hrDlSrc-> ',appsContext.state.videoPlayer.hrDlSrc)
              // console.log('hrdl url:', signedUrl.videoUrl)
              // console.log('hr file:', assetObj.highRezFileName)

              // fetch(signedUrl.videoUrl)
              //     .then(response => {
              //       const filename =  location.state.assetObj.highRezFileName
              //       response.blob().then(blob => {
              //         let url = window.URL.createObjectURL(blob);
              //         let a = document.createElement('a');
              //         a.href = url;
              //         a.download = filename;
              //         a.click();
              //     });
              //  });

              // vPlayer.isDownloading = false
              if(appsContext.state.user){


                // console.log('appsContext.state.videoPlayer.assetObj.mp4Urls:',appsContext.state.videoPlayer.assetObj.mp4Urls[0])
                // console.log('signedUrl.videoUrl-:', signedUrl.videoUrl)
                // vPlayer.hrDlSrc = (appsContext.state.user) ? signedUrl.videoUrl : ''

                if((appsContext.state.videoPlayer.assetObj.mp4Urls!=null)){
                  
                  tmp.hrDlSrc = appsContext.state.videoPlayer.assetObj.mp4Urls[0].replace(/\{|\}/g,'').substr(2)  
/*
async getDataFetch(){
    const response =
      await fetch("https://dog.ceo/api/breeds/list/all",
        { headers: {'Content-Type': 'application/json'}}
      )
    console.log(await response.json())
}

                  var params = {
                      Bucket: "examplebucket", 
                      Key: "HappyFace.jpg"
                     };
                     s3.headObject(params, function(err, data) {
                       if (err) console.log(err, err.stack); // an error occurred
                       else     console.log(data);           // successful response
                       
                       data = {
                        AcceptRanges: "bytes", 
                        ContentLength: 3191, 
                        ContentType: "image/jpeg", 
                        ETag: "\"6805f2cfc46c0f04559748bb039d69ae\"", 
                        LastModified: <Date Representation>, 
                        Metadata: {
                        }, 
                        VersionId: "null"
                       }
                       
                     });             
   */               
                }else{

                  tmp.hrDlSrc = signedUrl.videoUrl 
                }


              }else tmp.hrDlSrc = ''
                // console.log('getVideoUrl dispatching...',appsContext.state.videoPlayer, tmp)
              appsContext.dispatch({ type: "VPLAYER", vPlayer:{...appsContext.state.videoPlayer,...tmp, fileSize:fileSize} })
        }
        else{
          
            // console.log('signedUrl:', signedUrl)

            // if((typeof appsContext.state.videoPlayer.assetObj.mp4Urls!='undefined')){
            //     //add array of src videos
            //     vPlayer.srcUrls={[
            //       {src: appsContext.state.videoPlayer.assetObj.hlsUrl, type: 'video/ogg'},
            //       {src: appsContext.state.videoPlayer.assetObj.mp4Urls[0], type: 'video/mp4'}
            //       {src: appsContext.state.videoPlayer.assetObj.dashUrl, type: 'video/mp4'},
                                  
            //     ]}
            // }
            

           appsContext.dispatch({ type: "SRC", signedUrl: signedUrl.videoUrl })
           // console.log('appsContext.state.signedUrl:', appsContext.state.signedUrl)
           // Record a custom event
            // Analytics.record({
            //     name: `Asset-Viewed`,
            //     attributes: { user: appsContext.state.user.username, name: appsContext.state.videoPlayer.assetObj.name }
            // })
       }
        
      }catch(err){
        //add an image placehold for assets with no image
        // dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`}] });
        console.log('error: ', err)
      }
      

  }

  



  async function getVideoThumbnails(thumbNailUrl){
    try{
        
        // console.log('filename: ', filename)
        // return filename
        const videoThumbs = await API.get('shotwireAPI', '/createVideoProxy', {
                        'queryStringParameters': {
                          // 'setId': appsContext.state.videoPlayer.assetObj.realSetId,
                          // 'setDate': appsContext.state.videoPlayer.assetObj.setDate,
                          // 'setName': appsContext.state.videoPlayer.assetObj.name,
                          // 'assetName': (dlType === 'hrdl') ? appsContext.state.videoPlayer.assetObj.highRezFileName : appsContext.state.videoPlayer.assetObj.highRezFileName.replace('.mp4','-h264.mp4'),
                          'jobAction': 'getVideoThumbnails',
                          // 's3srcUrl' : srcObj.srcUrl,
                          'thumbNailUrl' : thumbNailUrl,
                          // 'jobTemplate': 'Shotwire-Custom-Job-User-'+ appsContext.state.user.username//'Shotwire-Watermarked-Proxies'                      
                        }
                      });
        // console.log('videoThumbs:', videoThumbs)
        return videoThumbs
        

      
      }catch(err){
        //add an image placehold for assets with no image
        // dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`}] });
        console.log('error: ', err)
      }
  }

  
  //settting auth state to 'loading' hide the default signin form
if (appsContext.state) {
// console.log('appsContext.state.videoPlayer.assetObj: ',appsContext.state.videoPlayer.assetObj)
  if(appsContext.state.videoPlayer.assetObj){
    // console.log('video page!')
     return(
          <div
            sx={{
              backgroundColor:'#000',
              backgroundImage:'url(https://s3.amazonaws.com/assets.shotloadr.com/shotwire-logo.png)',
              backgroundRepeat:'no-repeat',
              backgroundPosition: '50% 5%',
              backgroundSize: '50%',
              width:'100%',
              height:'1900px',
              // paddingBotton:'100px'
            }}
          >
          
                   
              
         
     

         {
          (appsContext.state.videoPlayer.assetObj.thumbNailUrl!=null) ?     
                (  
                 <ReactPlayer 
                  // ref={() => player}
                  // ref={handlePlayerRef()} 
                  // onReady={() => handlePlayerRef()}
                  // onReady={() => console.log(player.getInternalPlayer())}
                  // url={ encodeURI(appsContext.state.videoPlayer.assetObj.mp4Urls[0].replace(/^(\{S=)|\}/g,'')) } //mp4 urls, need new way of playng these... need to select by file name or tag
                  url={ ( typeof appsContext.state.videoPlayer.assetObj.cmafHlsUrl !== "undefined") 
                        ? (appsContext.state.videoPlayer.assetObj.cmafHlsUrl !== null) ? 
                        encodeURI(appsContext.state.videoPlayer.assetObj.cmafHlsUrl): (appsContext.state.videoPlayer.assetObj.cmafDashUrl !== null) ? 
                        encodeURI(appsContext.state.videoPlayer.assetObj.cmafDashUrl): encodeURI(appsContext.state.videoPlayer.assetObj.hlsUrl) 
                          : encodeURI(appsContext.state.videoPlayer.assetObj.hlsUrl) } //hls
                  width='100%'
                  controls 
                  playing={true}
                  muted={true}
                  // light={ encodeURI(appsContext.state.videoPlayer.assetObj.thumbNailUrl) } 
                  config={{ file: { attributes: { controlsList: 'nodownload', style: { width: '100%', height: 'auto', backgroundColor:'#000' }}} }} 
                  // sx={{backgroundColor:'#000'}}
                  />
                )
            // (appsContext.state.videoPlayer.assetObj.hlsUrl!=null) ?     
            //     (  
            //      <ReactPlayer url={ appsContext.state.videoPlayer.assetObj.hlsUrl } width='100%' controls light={ appsContext.state.videoPlayer.image } config={{ file: { attributes: { controlsList: 'nodownload', style: { width: '100%', height: 'auto' } }}}} />
            //     )
            // .react-player__preview { background-size: 100% !important; } where to put this style? for cover image bg
              :

                ( appsContext.state.signedUrl ?

                  <ReactPlayer url={ appsContext.state.signedUrl } width='100%' controls light={ appsContext.state.videoPlayer.image ? encodeURI(appsContext.state.videoPlayer.image) : `https://d1w2buicv2uw3m.cloudfront.net/V2/6B3E1A00BDAB94E4DA166A30A759D44C/assets/${appsContext.state.videoPlayer.assetObj.assetId}.jpg` } config={{ file: { attributes: { controlsList: 'nodownload', style: { width: '100%', height: 'auto' } }}}} />
                :
                  <Spinner 
                    animation="border" 
                    role="status"
                    variant="warning"
                    >
                  <span className="sr-only">Loading...</span>
                  </Spinner>  

                )
          } 
                 
              

               <WatermarkLogoDiv>
                  <Image
                    src={require(`../@geocine/gatsby-theme-organization/assets/shotwire-logo.png`)}
                    alt="Shotwire Video"
                    height={58}
                    width={121}
                    rounded
                  />
                </WatermarkLogoDiv>
                

              
                
                

                

{
//                <Col sm={12} lg={4}>
               
                   
                    
                   
                   
 
//                      { (appsContext.state.isAdmin===true || appsContext.state.isEditor===true || (appsContext.state.videoPlayer.assetObj.uploader ===  ( appsContext.state.user!==null ? appsContext.state.user.username:null )  ) ) ? 
//                        (
//                          <div sx={{
//                            width: '100%',
//                            padding: '10px',
 
//                          }}>
//                          <h4>Meta / IPTC</h4>
                         
 
    
//            <h5>Source Media Info</h5>
 
//             { ( appsContext.state.videoPlayer.assetObj.srcMediainfo !== null && typeof appsContext.state.videoPlayer.assetObj.srcMediainfo !== 'undefined') ?
//              <span>
//                <br />
//              <h6>File Container</h6>
//          {
 
 
//            // Object.keys(JSON.parse(appsContext.state.videoPlayer.assetObj.srcMediainfo).container).map((keyName, i) => (
             
           
 
//            //   <div key={keyName}>
//            //   <i>{keyName}:</i> {JSON.parse(appsContext.state.videoPlayer.assetObj.srcMediainfo).container[keyName]}
//            //   </div>
             
 
//            // ))
 
 
//          }
//          <br />
//            <h6>Video</h6>
//          {
 
 
//            // Object.keys(JSON.parse(appsContext.state.videoPlayer.assetObj.srcMediainfo).video[0]).map((keyName, i) => (
             
//            //   // (MetaEditable.includes(keyName)) ? 
//            //   //   <Form.Group controlId={keyName} key={keyName}>
//            //   //   <Form.Label>
//            //   //     {keyName.charAt(0).toUpperCase() + 
//            //   //        keyName.slice(1)}
//            //   //   </Form.Label>
            
//            //   //     <Form.Control as="textarea" placeholder={keyName} value={(appsContext.state.metaForm[keyName]==null)?'':appsContext.state.metaForm[keyName]} onChange={e => handleMetaChange(e)} disabled={appsContext.state.isMetaSaving} rows={(keyName=='caption'||keyName=='headline')?8:1} />
              
//            //   //   </Form.Group>
             
//            //   // :
 
//            //   <div key={keyName}>
//            //   <i>{keyName}:</i> {JSON.parse(appsContext.state.videoPlayer.assetObj.srcMediainfo).video[0][keyName]}
//            //   </div>
             
 
//            // ))
 
 
//          }
//          <br />
//            <h6>Audio</h6>
        
//          {
 
//            // (typeof JSON.parse(appsContext.state.videoPlayer.assetObj.srcMediainfo).audio !== 'undefined') ?
 
//            // (
//            //   Object.keys(JSON.parse(appsContext.state.videoPlayer.assetObj.srcMediainfo).audio[0]).map((keyName, i) => (
                       
//            //             // (MetaEditable.includes(keyName)) ? 
//            //             //   <Form.Group controlId={keyName} key={keyName}>
//            //             //   <Form.Label>
//            //             //     {keyName.charAt(0).toUpperCase() + 
//            //             //        keyName.slice(1)}
//            //             //   </Form.Label>
                      
//            //             //     <Form.Control as="textarea" placeholder={keyName} value={(appsContext.state.metaForm[keyName]==null)?'':appsContext.state.metaForm[keyName]} onChange={e => handleMetaChange(e)} disabled={appsContext.state.isMetaSaving} rows={(keyName=='caption'||keyName=='headline')?8:1} />
                        
//            //             //   </Form.Group>
                       
//            //             // :
           
//            //             <div key={keyName}>
//            //             <i>{keyName}:</i> {JSON.parse(appsContext.state.videoPlayer.assetObj.srcMediainfo).audio[0][keyName]}
//            //             </div>
                       
           
//            //           ))
//            //   )
//            // : 'hello'
 
 
//          }
 
//              </span>
//              :
//             <span></span>
//              }
 
//                          </div>
//                        )
//                        :
//                          <span></span>
//              }
 
 
                   
 
                   
 
//                </Col>
               } 
           
         </div>
               
           )

       
         
        
  }else{
    return(
          
          <Container>

          { 
          <h3>No Video Info</h3> 
        }
          <div sx={{
  padding: '10px',
 }} >
 
  <Spinner 
    animation="grow" 
    role="status"
    variant="danger"
    >
  <span className="sr-only">Loading...</span>
</Spinner>      
          </div>
          </Container>
          
          )
  }

}else{
        return(
          
          <Container>

          { <h3>No Video Info</h3> }
          
          </Container>
          
          )
}

 
}


export default VideoPlayerB